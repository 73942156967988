// markdownMixin.js
import MarkdownIt from 'markdown-it';

export default {
    data() {
        const md = new MarkdownIt({breaks: true});  // otherwise double space is needed for newline

        // all this mess below causes all links to open in a new tab
        // why? by that the folder view is kept open and not being loaded
        // each time the user returns. And I think it is a better UX.
        const defaultRender = md.renderer.rules.link_open || function(tokens, idx, options, env, self) {
            return self.renderToken(tokens, idx, options);
        };

        md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
            // Add target="_blank" to all links
            const aIndex = tokens[idx].attrIndex('target');

            if (aIndex < 0) {
                tokens[idx].attrPush(['target', '_blank']); // add new attribute
            } else {
                tokens[idx].attrs[aIndex][1] = '_blank';    // replace value of existing attr
            }

            // pass token to default renderer.
            return defaultRender(tokens, idx, options, env, self);
        };

        return {
            md
        };
    },
    methods: {
        markdown(text) {
            var res = this.md.render(text);
            // replace all </p> with </p><br> to have a newline after each paragraph
            // why? because foe some reason paragraphs are not shown separated...
            res = res.replace(/<\/p>/g, '</p><br>');
            // remove the last <br> as it is not needed (prevents empty line at the end)
            res = res.replace(/<br>\s*$/, '');
            return res;
        }
    }
}
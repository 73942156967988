<template>
    <div>
        <h1>{{ $t('help.title_tutorials') }}</h1>
        <a href="https://youtu.be/DEnEQDGmx38" target="_blank"><span class="text-h6">{{
            $t('help.tutorial_initial_login') }}</span></a>
        <br>
        <a href="https://www.google.com" target="_blank"><span class="text-h6">{{
            $t('help.tutorial_create_first_activity') }}</span></a>
        <br>
        <a href="https://www.google.com" target="_blank"><span class="text-h6">{{
            $t('help.tutorial_activity_components') }}</span></a>
    </div>
    <div>
        <h1 class="mt-5 mb-3">{{ $t('help.title_faq') }}</h1>
        <v-row justify="center">
            <v-col cols="6">
                <v-expansion-panels class="text-start" variant="popout">
                    <v-expansion-panel :title="$t('help.q_difference_google_forms')"
                        :text="$t('help.a_difference_google_forms') ">
                    </v-expansion-panel>
                    <v-expansion-panel :title="$t('help.q_is_it_free')" :text="$t('help.a_is_it_free')" >
                    </v-expansion-panel>
                    <v-expansion-panel :title="$t('help.q_who_developed')" :text="$t('help.a_who_developed')" >
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </div>
    <div class="mt-10 mb-3">
        <span class="text-body-1">{{ $t('help.contact_us') }}</span>
        <br>
        <span class="text-body-1">letsstudy.service@gmail.com</span>
    </div>
</template>

<script>
export default {

}
</script>

<style></style>